import $ from "jquery";

export class UploadInvoiceFile {

    /**
     *
     */
    constructor(documentTab) {

        let self = this;
        self.documentTab = documentTab;

        $('#upload-vendor-document-button').off('click').on('click', function () {
            self.upload();
        });
    }

    /**
     *
     */
    upload() {

        let self = this;
        let orderId = $('#upload_vendor_document').data('order_id');

        $.ajax({
            type: 'PATCH',
            url: '/vendor-site/orders/' + orderId + '/upload-document',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                file: $('#upload_vendor_document').data('base64'),
                type: $('#ship_document_type').val()
            },
        }).done(function (data) {

            self.documentTab.showDocuments();
            console.log(data);

        }).fail(function (xhr, textStatus, errorThrown) {

            console.log(errorThrown);

        });
    }

}
