export default class SendEtd {

    constructor() {
        this.events();
    }

    events() {
        let self = this;

        $(document).off('click', '.js-send-etd').on('click', '.js-send-etd', function () {
            self.send($(this));
        })
    }

    send(button) {

        let list = [];
        let orderId = button.data('order_id');
        $('input[name^=upload_files]').each(function () {
            if ($(this).is(':checked')) {
                list.push({
                    'order_id': orderId,
                    'path': $(this).val(),
                    'type': $(this).data('type')
                });
            }
        })

        $.ajax({
            type: 'POST',
            url: '/admin/electronic-trade-documents',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                'list': list
            },
        }).done(function (results) {

            button.html('Dosyalar alındı.<br><small>Gönderim belli aralıklarla sağlanır.</small>');

        }).fail(function (xhr) {

            console.log(xhr.responseJSON);

        });
    }
}
