import {formErrors} from './formErrors'
import {FormFileReader} from './formFileReader'

/*
	@usage

    <form action="" class="ajax_form" data-on-success="function_name" data-false="function_name">// Data false yoksa
        ....
        <button class="main_button"> // Diğer buttonları dikkate almaz.
    </form>
*/

export class AjaxForm {

    /**
     *
     * @param form
     */
    constructor(form) {

        // Public
        this.form = form;

        this.button = this.form.find('.main_button');

        // Private
        let self = this;

        this.form.find('textarea').each(function () {
            let newVal = $(this).val().replace(/<br>/g, "\n");
            $(this).val(newVal);
        })

        this.disableButton();

        new FormFileReader(this);

        $(document).on('click', '.form_msg_container .close_msg', function (e) {
            e.preventDefault();
            $(".form_msg_container").hide();
        })

        self.enableButton();

        $(this).find('input').on('keypress', function (e) {
            if (e.which == 10 || e.which == 13) {
                this.button.trigger('click');
            }
        });

        this.button.attr('type', 'button').off('click').on('click', function (e) {

            e.preventDefault();

            self.disableButton();

            new formErrors({}, this.form).resetFormStyle();

            self.ajax_post(self);

            return false;
        });
    }

    /**
     *
     */
    disableButton() {
        this.button.each(function () {
            let text = $(this).html();
            let textProcessing = $(this).attr('data-text_processing');

            if (typeof textProcessing == 'undefined') {
                textProcessing = 'Processing';
            }
            $(this).attr('data-text', text.replace(/"/g, "'"));
            $(this).html('<i class="fas fa-circle-notch fa-spin"></i> ' + textProcessing).attr('disabled', true);
        })
    }

    /**
     *
     */
    enableButton() {
        this.button.each(function () {
            let text = $(this).attr('data-text');
            $(this).html(text).attr('disabled', false);
        });
    }

    /**
     *
     */
    ajax_post() {
        let self = this;
        let new_data = this.getFormData();
        let url = this.form.attr('action');
        let method = this.form.attr('method');

        //
        let methodName = 'post';
        if (this.form.find('input[name=_method]').length > 0) {
            methodName = this.form.find('input[name=_method]').val().toLowerCase();
        }
        if (methodName == 'put') {
            method = 'put';
        } else if (methodName == 'delete') {
            method = 'delete';
        }

        $.ajax({
            type: method,
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: new_data
        }).done(function (data) {

            self.triggerOnSucces(data)

        }).fail(function (xhr, textStatus, errorThrown) {

            self.triggerOnFail(xhr)

        });
    }

    /**
     *
     */
    triggerOnSucces(data) {
        try {

            this.enableButton()

            this.refreshDataTable()

            // Get function
            let functionName = this.form.data('on-success')
            if (typeof functionName == 'undefined') {
                functionName = 'successDefault'
            }

            const {OnSuccess} = require('./onSuccess/' + functionName)
            let obj = new OnSuccess(this)
            obj.init(data)
        } catch (e) {
            console.error(e)
        }
    }

    /**
     *
     */
    triggerOnFail(xhr) {
        try {
            this.enableButton();

            // Get function
            let functionName = this.form.data('on-fail')
            if (typeof functionName == 'undefined') {
                functionName = 'failDefault'
            }

            const {OnFail} = require('./onFail/' + functionName)
            let obj = new OnFail(this)
            obj.init(xhr)
        } catch (e) {
            console.error(e)
        }
    }

    /**
     *
     */
    getFormData() {
        let new_data = {}
        let self = this
        $('input[type=file]').each(function () {
            self.form.trigger('change')
        })

        // Input
        this.form.find('input').each(function () {
            let val = ''

            let isMultiple = false
            if (typeof $(this).attr('name') != 'undefined' && $(this).attr("name").slice(-2) == '[]') {
                isMultiple = true

                // Define array
                if (typeof new_data[$(this).attr('name')] == 'undefined') {
                    new_data[$(this).attr('name')] = [];
                }
            }

            if ($(this).attr("type") == 'file') {
                val = $(this).attr('data-base64');
                new_data[$(this).attr('name')] = val;

            } else if ($(this).attr('type') == 'checkbox') {

                if ($(this).is(':checked')) {
                    let val = $(this).val();
                    if (isMultiple) {
                        new_data[$(this).attr('name')].push(val);
                    } else {
                        new_data[$(this).attr('name')] = val;
                    }
                }
            } else if ($(this).attr('type') == 'radio') {
                val = $(this).parent().find('input[name=' + $(this).attr('name') + ']:checked').val();
                new_data[$(this).attr("name")] = val;

            } else {
                // text or hidden
                val = $(this).val().replace(/\\/g, '/');
                if (typeof $(this).attr('name') != 'undefined') {
                    if (isMultiple) {
                        new_data[$(this).attr('name')].push(val);
                    } else {
                        new_data[$(this).attr('name')] = val;
                    }
                }
            }
        });

        // Select
        this.form.find("select").each(function () {
            let isMultiple = false;
            if (typeof $(this).attr("name") != 'undefined' && $(this).attr("name").slice(-2) == "[]") {
                isMultiple = true;

                // Define array
                if (typeof new_data[$(this).attr('name')] == 'undefined') {
                    new_data[$(this).attr('name')] = [];
                }
            }
            let val = $(this).val();
            if (isMultiple) {
                new_data[$(this).attr('name')].push(val);
            } else {
                new_data[$(this).attr('name')] = val;
            }
        });

        this.form.find('textarea').each(function () {

            let val = $(this).val().replace(/\r\n/g, "\\n");
            val = val.replace(/\n/g, "<br>");
            new_data[$(this).attr("name")] = val;
        })

        return new_data;
    }

    /**
     * Ordering will refresh data table
     */
    refreshDataTable() {
        $('.reload-datatable').trigger('click')
    }
}
