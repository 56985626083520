export default class VendorRoutes {

    /**
     * router class published at 2023-08-25 13:21:59
     */
    constructor() {
        this.routes = {"vendor_address.store":"vendor-site\/vendor_address",
		"vendor_address.index":"vendor-site\/vendor_address",
		"vendor_address.create":"vendor-site\/vendor_address\/create",
		"vendor_address.show":"vendor-site\/vendor_address\/{vendor_address}",
		"vendor_address.edit":"vendor-site\/vendor_address\/{vendor_address}\/edit",
		"vendor_address.update":"vendor-site\/vendor_address\/{vendor_address}",
		"vendor_address.destroy":"vendor-site\/vendor_address\/{vendor_address}",
		"vendor.address.ajax":"vendor-site\/vendor-address-list-ajax\/{user_id}",
		"vendor.customer_address.ajax":"vendor-site\/vendor-customer-address-list-ajax\/{user_id}",
		"vendor.customer_address.search":"vendor-site\/vendor-customer-address-search\/{user_id}",
		"vendor":"vendor-site",
		"vendor_messages":"vendor-site\/messages",
		"api.add_city":"vendor-site\/api-add-city",
		"api.add_state":"vendor-site\/api-add-state",
		"api_print_label":"vendor-site\/api-print-label",
		"vendor-customer-account.index":"vendor-site\/vendor-customer-account",
		"vendor-customer-account.create":"vendor-site\/vendor-customer-account\/create",
		"vendor-customer-account.store":"vendor-site\/vendor-customer-account",
		"vendor-customer-account.show":"vendor-site\/vendor-customer-account\/{vendor_customer_account}",
		"vendor-customer-account.edit":"vendor-site\/vendor-customer-account\/{vendor_customer_account}\/edit",
		"vendor-customer-account.update":"vendor-site\/vendor-customer-account\/{vendor_customer_account}",
		"vendor-customer-account.destroy":"vendor-site\/vendor-customer-account\/{vendor_customer_account}",
		"vendor.customer_account.ajax":"vendor-site\/vendor-customer-account-ajax",
		"vendor.shipping_prices":"vendor-site\/shipping-prices",
		"vendor.modal_price":"vendor-site\/get-price-modal",
		"api.vendor.modal_price":"vendor-site\/api-get-price",
		"admin.shipping_prices.download":"vendor-site\/shipping-prices\/download\/{id}",
		"vendor_integrations":"vendor-site\/vendor-integration",
		"vendor.entegration_operation.ajax":"vendor-site\/entegration-operations\/ajax",
		"vendor.entegration.operations":"vendor-site\/entegration-operations\/{id?}",
		"vendor.order.get_etsy_code":"vendor-site\/get-etsy-code",
		"vendor.order.createShipment":"vendor-site\/etsy-create-shipment",
		"vendor.order.changeOrderStatus":"vendor-site\/etsy-change-order-status",
		"vendor.order.getOrders":"vendor-site\/etsy-get-orders\/{id?}",
		"vendor.integration.shopify":"vendor-site\/integration\/shopify\/{integtarion_data_id}\/update",
		"vendor.integration.disable_shopify":"vendor-site\/integration\/shopify\/{integtarion_data_id}\/disable",
		"vendor.integration.get_orders":"vendor-site\/integration\/{integtarion_data_id}\/orders",
		"vendor_notifications":"vendor-site\/notifications",
		"vendor.notification.ajax":"vendor-site\/notifications\/ajax",
		"vendor_orders.index":"vendor-site\/vendor_orders",
		"vendor_orders.create":"vendor-site\/vendor_orders\/create",
		"vendor_orders.store":"vendor-site\/vendor_orders",
		"vendor_orders.show":"vendor-site\/vendor_orders\/{vendor_order}",
		"vendor_orders.edit":"vendor-site\/vendor_orders\/{vendor_order}\/edit",
		"vendor_orders.update":"vendor-site\/vendor_orders\/{vendor_order}",
		"vendor_orders.destroy":"vendor-site\/vendor_orders\/{vendor_order}",
		"vendor.order.export_report":"vendor-site\/order-report-excel",
		"vendor.orders.ajax":"vendor-site\/orders_ajax",
		"vendor.documents.destroy":"vendor-site\/orders\/{order_id}\/documents\/{document_name}",
		"vendor.order.create_vbt_invoice":"vendor-site\/orders\/{order_id}\/vbt-invoice\/create",
		"vendor.order.create_vbt_invoice_exporgin_sas":"vendor-site\/orders\/{order_id}\/vbt-exporgin-invoice\/create",
		"vendor.order.destroy_vbt_invoice":"vendor-site\/orders\/vbt-invoice\/{ettn}\/destroy",
		"vendor.order.save_address":"vendor-site\/vendor-save-order-address",
		"vendor.order.create_document":"vendor-site\/orders\/{order_id}\/create-documents",
		"vendor.order.documents":"vendor-site\/orders\/{order_id}\/documents",
		"vendor.order.create_custom_document":"vendor-site\/orders\/{order_id}\/document-create-custom-doc",
		"vendor_orders":"vendor-site\/orders\/{id?}",
		"vendor_orders.file.index":"vendor-site\/order-file",
		"vendor_orders.file.import":"vendor-site\/order-file-import",
		"vendor_orders.empty_file.export":"vendor-site\/order-file-export",
		"vendor.order.get-tracking":"vendor-site\/order\/get-tracking-data\/{orderId}",
		"vendor.order.show-tracking":"vendor-site\/order\/show-tracking-data\/{orderId}",
		"vendor.order.show_status":"vendor-site\/order-status",
		"vendor_payment_account.index":"vendor-site\/vendor_payment_account",
		"vendor_payment_account.create":"vendor-site\/vendor_payment_account\/create",
		"vendor_payment_account.store":"vendor-site\/vendor_payment_account",
		"vendor_payment_account.show":"vendor-site\/vendor_payment_account\/{vendor_payment_account}",
		"vendor.payments.get_installments":"vendor-site\/payments\/{payment_id}\/payment_installments",
		"vendor.payment_account.ajax":"vendor-site\/payment_account_ajax",
		"vendor_entegration_data.index":"vendor-site\/vendor_entegration_data",
		"vendor_entegration_data.create":"vendor-site\/vendor_entegration_data\/create",
		"vendor_entegration_data.store":"vendor-site\/vendor_entegration_data",
		"vendor_entegration_data.show":"vendor-site\/vendor_entegration_data\/{vendor_entegration_datum}",
		"vendor_entegration_data.edit":"vendor-site\/vendor_entegration_data\/{vendor_entegration_datum}\/edit",
		"vendor_entegration_data.update":"vendor-site\/vendor_entegration_data\/{vendor_entegration_datum}",
		"vendor_entegration_data.destroy":"vendor-site\/vendor_entegration_data\/{vendor_entegration_datum}",
		"vendor_message.index":"vendor-site\/vendor_message",
		"vendor_message.create":"vendor-site\/vendor_message\/create",
		"vendor_message.store":"vendor-site\/vendor_message",
		"vendor_message.show":"vendor-site\/vendor_message\/{vendor_message}",
		"vendor_message.edit":"vendor-site\/vendor_message\/{vendor_message}\/edit",
		"vendor_message.update":"vendor-site\/vendor_message\/{vendor_message}",
		"vendor_message.destroy":"vendor-site\/vendor_message\/{vendor_message}",
		"vendor.search":"vendor-site\/search\/top-page",
		"api.gtip.search":"vendor-site\/api-gtip",
		"selectCity":"vendor-site\/selectCity",
		"api.select.country":"vendor-site\/select-single-country",
		"api.select.state":"vendor-site\/select-single-state",
		"api.select.city":"vendor-site\/select-single-city",
		"select.country.state_list":"vendor-site\/select\/country\/state_list"};
        this.debug = false;
        if(this.debug){
            console.log(this.routes);
        }
    }

    /**
     * Usage :
     * let routes = new AdminRoutes();
     * let url = routes.get("admin.courier.progress", {\'order_id\': orderId});
     *
     */
    get(name, parameterList = {}){
        let url =  this.routes[name];
        if(parameterList != {}){
            Object.keys(parameterList).forEach(key => {
                if(this.debug){
                    console.log(key,parameterList[key] )
                }
                url = url.replace('{'+ key +'}',parameterList[key]);
            })
        }
        return url;
    }

    /**
     *
     */
    showAll(){
        console.log(this.routes);
    }
}
