import $ from "jquery";
import {formErrors} from "../../../forms/formErrors";
import {ReceiverAddressForm} from "./receiverAddressForm";

export class Address {

    /**
     *
     */
    constructor() {
        this.events();
        this.form = new ReceiverAddressForm();
    }

    /**
     *
     */
    events() {

        let self = this;

        /**
         Update Address
         */
        $('.js-order_update_address').click(function () {

            self.closeAllTabs();

            $('#form_order #order-tab-header').hide();

            $('#form_order .tab-content #address').addClass('show active');

            self.form.resetFormErrors();
        });

        /**
         * New address
         */
        $('.js-order_add_address').click(function () {

            self.form.reset();

            self.closeAllTabs();

            $('#form_order #order-tab-header').hide();

            $('#form_order .tab-content #address').addClass('show active');

        });

        /**
         *
         */
        $('.js-address_go_back').click(function () {
            $('#form_order #order-tab-header').show();

            self.closeAllTabs();

            $('#form_order .tab-content #home').addClass('show active');

            $('#modal_theme_primary .modal-footer').show();
        });

        /**
         *
         */
        $('.js-save_address').click(function () {
            let url = $(this).data('url');
            self.saveAddress(url);
        });
    }

    /**
     * Closes all tabs
     */
    closeAllTabs() {
        $('#form_order .tab-content .tab-pane').removeClass('show active')
        $('#modal_theme_primary .modal-footer').hide();
    }

    /**
     *
     */
    saveAddress(url) {

        let self = this;

        let data = {
            order_id: self.sanitiseInt($('#form_order').data('order_id')),
            receiver_address_id: self.sanitiseInt($('input[name=receiver_address_id]').val()),

            full_name: $('input[name=full_name]').val(),
            company_name: $('input[name=company_name]').val(),
            phone: $('input[name=phone]').val(),
            email: $('input[name=email]').val(),
            address: $('textarea[name=address]').val(),
            postal_code: $('input[name=postal_code]').val(),
            country_id: self.sanitiseInt($('select[name=country_id]').val()),
            state_id: self.sanitiseInt($('select[name=state_id]').val()),
            city_id: self.sanitiseInt($('select[name=city_id]').val()),

            phone_code: $('#phone_code').val(),
        };

        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {

            let form = new ReceiverAddressForm();
            form.createAddress(result);

        }).fail(function (xhr) {

            self.showErrors(xhr.status, xhr.responseJSON)

        });
    }

    /**
     *
     * @param value
     * @returns {number|string}
     */
    sanitiseInt(value) {
        try {
            let newValue = parseInt(value);
            if (isNaN(newValue)) {
                return '';
            }
            return newValue;
        } catch (e) {
            console.log(e);
        }
    }

    /**
     *
     * @param status
     * @param jsonErrorList
     */
    showErrors(status, jsonErrorList) {

        if (status == 403) {
            alert('This action is unauthorized.');
            return;
        }

        // Response may be in errors message bag
        if (typeof jsonErrorList.errors != 'undefined') {
            jsonErrorList = jsonErrorList.errors;
        }

        new formErrors(jsonErrorList, $('#form_order'));
    }
}


