import $ from "jquery";

export class Registration {

    constructor() {

        let self = this;

        $('#registration_code').css('width', '100%').select2({
            dropdownParent: $('#modal_theme_primary')
        });

        $('#iso2').css('width', '100%').select2({
            dropdownParent: $('#modal_theme_primary')
        });

        $(document).off('click', '.js-delete_registration').on('click', '.js-delete_registration', function () {
            $(this).parent().parent().remove();
        });

        $('.js-add-registration-number').on('click', function () {
            self.add();
        });

    }

    add() {
        let template = $('#registration_template').html();
        template = template.replace('__TYPE_CODE__', $('#registration_code').val());
        template = template.replace('__NUMBER__', $('#registration_number').val());
        template = template.replace('__ISO2__', $('#iso2').val());
        $('.js-registration-number-list').append(template);
    }

    toTextarea() {
        let list = [];
        $('.js-registration-number-list .row').each(function (index, item) {
            list.push({
                "type_code": $(this).find('input[name=type_code]').val(),
                "number": $(this).find('input[name=number]').val(),
                "iso2": $(this).find('input[name=iso2]').val(),
            })
        })
        $('#registration_numbers').val(JSON.stringify(list));
    }
}
