import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    /**
     *
     * @param item
     * @param data
     * @returns {(*|string)[]}
     */
    constructor(item, data) {

        super();

        this.item = item;

        let self = this;

        let color = 'f00';
        let statusName = 'Undefined';
        if (typeof item != 'undefined') {
            color = item.order_status.color;
            statusName = item.order_status.name;
        }

        let currency = ''
        if (item.currency_value_on_pickup != null) {
            let value = parseFloat(item.currency_value_on_pickup).toFixed(2);
            currency = `
                <div >
                    <small class="js-focusable" data-toggle="popover" title="<small>Siparişin kargoya verildiği gunkü kur.</small>">
                       ` + value + ` USD / TL
                    </small>
                </div>
            `;
        }

        let userGroupLink = `
            <div class="open-modal text-danger" data-url="` + data.update_user_group_url.replace('###', item.user_group.id) + `" data-onload="adminUserGroup">
                ` + item.user_group.name + `
            </div>`;

        let userLink = `
            <div class="open-modal" data-url="` + data.update_user_url.replace('###', item.user_id) + `" data-onload="adminUserModal">
                ` + item.full_name + `
            </div>`;

        return [
            item.id,
            item.tracking_number,
            self.shippingLogo(item),
            userLink + userGroupLink,
            `<div class="open-modal" data-url="` + data.update_url.replace('###', item.order_id) + `" data-onload="vendorOrderModal" role="button">
                <i class="fas fa-external-link-alt"></i> ` + item.order_id + `
            </div>`,
            `<span class="order_satus" style="background:#` + color + `;">
                ` + statusName + `
            </span>` + this.ifBilled(item),
            item.desi,
            self.input('shipment_actual_desi', item),
            self.input('shipment_price', item, true, '$'),
            self.input('shipment_cost', item, true, '$') + currency,
            self.input('shipment_actual_cost', item, false, 'TL') + `
                <div class="shipment_actual_cost_in_usd_container js-focusable">
                    <small>
                        <span class="shipment_actual_cost_in_usd"></span> $
                    </small>
                </div>`,
            self.input('currency_value', item, false, 'TL'),

            self.yesNo(item.is_paid),
            self.timeAgo(item.created_at),
            this.invoiceButton(),
            this.deleteInvoiceButton(),
            this.updateInvoiceButton(),
            `
            <div class="text-center js-approve-account" data-id="` + item.id + `" role="button">
                <a class="btn btn-circle btn-sm ` + (item.is_approved ? 'btn-success' : 'btn-secondary') + ` ">
                    <i class="fas fa-check"></i>
                </a>
            </div>`,
            self.input('invoice_no', item, false),
        ];
    }

    /**
     *
     * @returns {string}
     */
    invoiceButton() {
        if (this.item.invoice_no == null) {
            return `
            <div class="text-center js-invoice-button"  data-id="` + this.item.id + `" role="button">
                <a class="btn btn-circle btn-sm btn-success">
                    <i class="fas fa-file-invoice-dollar"></i>
                </a>
            </div>`;
        } else {
            return this.disabledButton('fa-file-invoice-dollar', 'Already issued. invoice no :' + this.item.invoice_no);
        }
    }

    /**
     *
     * @returns {string}
     */
    deleteInvoiceButton() {
        if (this.item.invoice_no != null) {
            return `
            <div class="text-center js-delete-invoice-button"  data-id="` + this.item.id + `" role="button">
                <a class="btn btn-circle btn-sm btn-danger">
                    <i class="fas fa-file-invoice-dollar"></i>
                </a>
            </div>`;
        } else {
            return this.disabledButton('fa-file-invoice-dollar');
        }
    }

    /**
     *
     * @returns {string}
     */
    updateInvoiceButton() {
        if (this.item.invoice_no != null) {
            return `
            <div class="text-center js-update-invoice-button"  data-id="` + this.item.id + `" role="button">
                <a class="btn btn-circle btn-sm btn-info">
                    <i class="fas fa-file-invoice-dollar"></i>
                </a>
            </div>`;
        } else {
            return this.disabledButton('fa-file-invoice-dollar');
        }
    }

    /**
     *
     * @param field
     * @param item
     * @param isDisabled
     * @param append
     * @returns {string}
     */
    input(field, item, isDisabled, append = '') {
        let value = 0;
        let type = 'number';

        switch (field) {
            case 'shipment_price':
                value = item.shipment_price;
                break;
            case 'shipment_actual_cost':
                value = item.shipment_actual_cost;
                break;
            case 'shipment_actual_desi':
                value = item.shipment_actual_desi;
                break;
            case 'shipment_cost':
                value = item.shipment_cost;
                break;
            case 'currency_value':
                value = item.currency_value;
                break;
            case 'invoice_no':
                value = item.invoice_no;
                type = 'text';
                break;
        }
        value = (value == null) ? '' : value;
        value = (value == 0) ? '' : value;

        if (this.isBilled(item)) {
            isDisabled = true;
        }

        let input = `
            <input type="` + type + `" value="` + value + `" class="form-control form-control-sm js-updatable-input hide-arrows"
                data-field="` + field + `"
                data-id="` + item.order_id + `"
                ` + (isDisabled ? 'disabled' : '') + `>`;

        if (append != '') {
            input = `
             <div class="input-group input-group-sm" style="width:100px">
                ` + input + `
                <div class="input-group-append">
                  <span class="input-group-text js-focusable" id="inputGroupAppend-` + item.order_id + `">` + append + `</span>
                </div>
              </div>`;
        }
        return input;
    }

    isBilled(item) {
        return typeof item.bill_no == 'string' && item.bill_no.length > 1;
    }

    /**
     *
     * @param item
     * @returns {string}
     */
    shippingLogo(item) {
        if (item.shipping_id != null) {
            let changed = '';
            if (item.shipping_variety_id != item.shipping_variety_id_vendor) {
                changed = '+ ' + item.shipping_variety_id + ', ' + item.shipping_variety_id_vendor;
            }
            return `
                <div class="border bg-white shipping-logo" data-toggle="tooltip"  title="` + item.shipping_variety_name + `">
                    <img src="/storage/img/shipping/` + item.shipping_id + `.webp" alt="">
                </div>
            ` + changed;
        }
        return `<div class="text-center"> - </div>`;
    }

    ifBilled(item) {
        return (this.isBilled(item) ? `<br><span class="order_satus mt-1" style="background:#8f1f00;display:block;margin-top:2px !important">
                billed
            </span>
            <a href="`+item.invoiceFile+`" target="_blank">
                <small><b>` + item.bill_no + `</b></small>
            </a>` : '')
    }
}
