import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;

        let type = '';
        if (item.is_invoice) {
            type += `<span class="badge badge-pill badge-danger m-1">invoice</span>`;
        }
        if (item.is_warehouse) {
            type += `<span class="badge badge-pill badge-danger m-1">warehouse</span>`;
        }

        if (item.is_return) {
            type += `<span class="badge badge-pill badge-danger m-1">return</span>`;
        }
        return [
            item.id,
            item.full_name,
            item.company_name,
            item.city_name,
            type,
            self.updateButton(data, item.id, 'vendorAddressModal'),
            self.deleteButton(item),
        ];
    }
}
