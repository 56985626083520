/**
 *
 */
export class FormModalOnLoad {

    /**
     *
     */
    constructor() {
        this.init()
    }

    /**
     *
     */
    init() {
        //
    }
}
