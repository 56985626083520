import {formErrors} from "../../forms/formErrors";
import {dataTable} from "../../datatables/dataTable";

/**
 *
 * @constructor
 */
export class FormModalOnLoad {

    constructor() {

        this.userGroupPrice = $("input[name=user_grp_prices_id]").val();
        this.events();
        this.createTable();
    }

    /**
     *
     */
    events() {
        var self = this;
        $(document).off('click', "#add_user_group_price_countries").on('click', "#add_user_group_price_countries", function () {
            var ajaxUrl = $(this).data("url");
            self.addPrice(ajaxUrl);
        })

        /**
         * Create shippng servicess
         */
       
    }


    /**
     * Updates shipping zones
     *
     * @param {number} shippingId
     * @param {object} Fbutton
     */
    addPrice(ajaxUrl) {

        var self = this;

        var data = {
            user_grp_prices_id: this.userGroupPrice,
            new_price: $("input[name=new_price]").val(),
            country_name: $("select[name=country_name]").val(),
        };

        $.ajax({
            type: 'post',
            url: ajaxUrl,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {

            self.resetForm();
            return true;

        }).fail(function (xhr, textStatus, errorThrown) {

            var form = $("#user_group_price_form")
            var errors = new formErrors(xhr.responseJSON.errors, form);
            errors.showErrors();

            return false;

        });
    }

    /**
     *
     */
    createTable() {
        this.table = new dataTable(".prices-data-table");
    }

    /**
     *
     */
    resetForm() {
        $("input[name=user_grp_prices_id]").val('');
        $("input[name=new_price]").val('');
        $("input[name=country_name]").val('');        
        this.table.refresh()
    }
}
