import $ from "jquery";

export default class AdminChangeStatus {

    constructor() {

        this.select = $('#order-change-status');
        if (this.select.length > 0) {
            this.events()
        }
    }

    events() {

        let self = this;

        this.select.off('change').on('change', function () {
            let id = $(this).attr('data-id');
            let data = {
                new_status: self.select.find(':selected').val()
            };

            self.changeStatus(id, data);
        })
    }


    changeStatus(id, data) {

        $.ajax({
            type: 'POST',
            url: '/admin/order-update-status/' + id,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data
        }).done(function () {

            alert('Status changed');

        }).fail(function (xhr) {

            alert('Console for errors');

            let content = `
                <h5>ERROR</h5>
                <p>` + xhr.responseJSON.error + `</p>`
            console.log(content);

        });
    }
}
