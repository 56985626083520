import {newModal} from '../../newmodal/formModal'

export class OnSuccess {

    /**
     *
     * @param form
     */
    constructor(form) {
        this.form = form
        this.modal = new newModal()
    }

    /**
     *
     * @param data
     */
    init(data) {

        this.resetMsg();

        if (data.is_test) {
            this.showTestResults(data);
        } else {
            this.showResults(data);
        }

    }

    showTestResults(data) {
        let msg = ' Aşağıdaki değerler doğru ise test modunu kapatıp yüklemeyi başlatın.<br>';
        let values = '';
        $.each(data.data, function (index, row) {
            values += index + `, Fiyat = ` + row.price + `, Desi = ` + row.actual_desi + '<br>';
        });

        this.showMsg(msg + '<small>' + values + '</small>');
    }

    showResults(data) {
        let msg = '<b>' + data.total_orders + '</b> sipariş bulundu.<br>';

        if (data.matched_orders > 0) {
            msg += '<b>' + data.matched_orders + '</b> sipariş güncellendi.';
        } else {
            msg += 'Eşleşen tracking id bulunamadı.';
        }

        this.showMsg(msg);
    }

    showMsg(msg) {
        $('.js-form-success').removeClass('d-none').html(msg);
    }

    /**
     *
     */
    resetMsg() {
        $('.js-form-errors').html('').addClass('d-none');
        $('.js-form-success').addClass('d-none').html('')
    }
}
