import {FormatDataTable} from '../formatDataTable';
import {TrackingLink} from "../../trackingLink";

/**
 *
 */
export class DataTableOnLoad extends FormatDataTable {

    /**
     *
     */
    constructor(item, data) {

        super();

        this.item = item;
        let self = this;

        let trackingLink = new TrackingLink(item);

        const orderDate = new Date(item.created_at);
        let options = [
            {day: 'numeric'},
            {month: 'short'},
            {year: 'numeric'}
        ];
        let joined = this.join(orderDate, options, '-');

        return [
            this.id(item),
            self.vendor(item, data),
            item.which_platform_string,
            item.order_id +
            self.lableWhenEtd(item) +
            self.lableIsPrinted(item),
            `<span class="order_satus" style="background:#` + item.order.order_statuss.color + `;">
                ` + item.order.order_statuss.name + `
            </span>`,
            self.integrationLogo(),
            item.address.address_mail[0].mail,
            typeof item.address.full_name != 'undefined' ? '<b>' + item.address.full_name + '</b>' : '',
            self.shippingLogo(item),
            trackingLink.show(),
            (item.is_express == 1 ? 'express' : 'economic'),
            item.country,
            '<div class="text-nowrap">' + joined + '</div>',

            // Shares function with vendor
            self.updateButton(data, item.id, 'vendorOrderModal'),
            self.deleteButton(item),
            self.lastButton(item)
        ];
    }

    join(date, options, separator) {
        function format(option) {
            let formatter = new Intl.DateTimeFormat('en', option);
            return formatter.format(date);
        }

        return options.map(format).join(separator);
    }

    /**
     *
     */
    integrationLogo() {
        let order = this.item.order;
        let integration_data = order.integration_data;

        if (order.which_platform != 7) {
            return '';
        }

        if (typeof integration_data == 'undefined') {
            return '';
        }

        if (integration_data == null) {
            return '';
        }

        if (typeof integration_data != 'undefined') {
            return '<img src="/img/integrations/' + integration_data.entegration_id + '.png" class="vendor-img-sm">';
        }
    }

    /**
     *
     */
    shippingLogo(item) {
        if (item.shipping_id != null) {
            return '<img src="/storage/img/shipping/' + item.shipping_id + '.webp" alt="" style="width:30px">';
        }
        return `<div class="text-center"> - </div>`;
    }

    /**
     * Adminde bmölümünde bu parça gereksiz olabilir.
     */
    id(item) {

        return `
            <div
                id="order_` + item.id + `"
                data-order_id="` + item.id + `"
                data-order="` + JSON.stringify(item).replaceAll("'", '`').replaceAll('\"', "'") + `"
                data-is_admin="true"
                data-description="` + (item.description ? item.description : '') + `"
                data-is_labelled="` + (item.tracking_number == null ? 'false' : 'true') + `"
                data-is_labellable="` + item.is_labellable + `"
                data-is_labellable_reason="` + item.is_labellable_reason + `"
                data-real_status="` + item.real_status + `" >
                ` + item.id + `
            </div>`
    }

    /**
     *
     */
    vendor(item, data) {
        let userUpdateUrl = data.update_user_url.replace('###', item.order.user_id);

        return `
            <div class="text-center  open-modal" data-url="` + userUpdateUrl + `" data-onload="adminUserModal" role="button">
                <b class="text-danger">` + item.order.user.company_name + `</b>
                <br>
                 ` + item.order.user.full_name + `
            </div>`;
    }

    /**
     *
     */
    shipmentPrice() {
        let price = this.item.shipment_actual_price;
        if (price > 0) {
            return '<b>' + price + '</b><br><small style="color:#999">' + this.item.shipment_currency_text + '</small>'
        }
        return `<div class="text-center"> - </div>`;
    }

    lableWhenEtd(item) {
        if (item.is_etd) {
            return `<span class="d-inline-block bg-danger text-white pl-1 pr-1"><small>ETD</small></span>`;
        }
        return ''
    }

    /**
     *
     */
    lableIsPrinted(item) {
        return `
        <br>
        <span class="d-inline-block text-white ` + (item.is_printed ? 'bg-success' : 'bg-secondary') + ` pl-1 pr-1 mt-1">
            <small>
                ` + (item.is_printed ? 'PRINTED' : 'NOT PRINTED') + `
            </small>
        </span>`;
    }

    /**
     *
     */
    lastButton(order) {
        let button = '';
        if (order.real_status == 13) {
            button = `<span class="btn btn-circle btn-sm btn-secondary js-first-label"  role="button" data-order_id="` + order.id + `">
                    <i class="fas fa-share"></i>
                </span>`;
        } else {
            button = order.real_status != 14 ?
                `<span class="btn btn-circle btn-sm btn-warning js-open-label"  role="button">
                    <i class="fas fa-folder-plus"></i>
                </span>` : `
                <span
                    class="btn btn-circle btn-sm btn-info js-print-single-zpl-label"
                    role="button"
                    data-id="` + order.id + `" >
                    <i class="fas fa-print"></i>
                </span>`;
        }

        return ` <div class="text-center"> ` + button + ` </div>`;
    }
}
