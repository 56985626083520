import $ from "jquery";
import {ReceiverAddressForm} from "./receiverAddressForm";

export class CustomerAddressList {

    constructor() {

        let self = this;
        $('.js-select-reciever-address').each(function () {
            self.createSelect2($(this));
        });

    }

    /**
     *
     * @param item
     */
    createSelect2(item) {

        let url = item.data('url');

        item.css({'width': '100%'}).select2({
            placeholder: 'Eski gönderi adresleri',
            allowClear: true,
            minimumInputLength: 3,
            dropdownParent: $('#address-dropdown-container'),
            ajax: {
                url: url,
                delay: 250,
                method: 'post',
                dataType: 'json',
                data: function (params) {
                    return {
                        search: params.term
                    };
                },
                processResults: function (data) {
                    return data;
                },

            },
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return data.html;
            },
            templateSelection: function (data) {
                return data.text;
            }
        });

        item.on('select2:select', function (e) {

            try {
                let data = e.params.data;

                let form = new ReceiverAddressForm();

                form.createAddress(data);

                item.val('').trigger('change');

            } catch (e) {
                alert(e.message)
            }

        });
    }
}
