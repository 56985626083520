import $ from 'jquery';
import {Location} from './vendorOrderModal/location';
import {SelectCity} from '../../selectCity';

/**
 *
 */
export class FormModalOnLoad {

    /**
     *
     */
    constructor() {

        let self = this;

        $(document).off('click', '.js-delete_parent').on('click', '.js-delete_parent', function (e) {
            self.resetErrors();
            let url = $(this).data('url');
            self.destroy(url, $(this));
        });

        let location = new Location('#modal_theme_primary');
        // location.reset(); // Hata veriyordu kaldırıldı

        $(".full_search").removeClass("d-none");
        $(".toggle_full_search").removeClass("d-none");

        new SelectCity();

        $(".admin_user_type").trigger('change');

    }

    /**
     * change_location button event
     */
    changeLocationButton() {
        $("#change_location").on("click", function () {
            $(this).hide();
            $(".full_search").removeClass("d-none");
            $(".toggle_full_search").removeClass("d-none");
        });
    }

    /**
     *
     */
    resetErrors() {
        $('.js-parent-erros').addClass('d-none');
    }
}
