import $ from "jquery";

export class UploadInvoice {

    constructor() {
        this.events();
    }

    events() {

        let self = this;

        $(document).off('change', '.js-file-input').on('change', '.js-file-input', function () {

            let fileInput = $(this);

            let fileList = fileInput[0].files;

            let file = fileList[0];
            /*
            Ajax form alredy convert to base64

            let reader = new FormFileReader(file);
            reader.readAsText(file);
            reader.onload = function () {
                fileInput.attr('data-base64', reader.result);
                self.upload(reader.result)
            };

            reader.onerror = function () {
                console.log(reader.error);
            };
            */
        });

        $(document).off('click', '#upload_invoice_button').on('click', '#upload_invoice_button', function () {
            self.upload()
        });
    }


    /**
     *
     * @param {json} data
     */
    upload() {

        let self = this;
        let orderId = $('.js-file-input').data('order_id');
        let url = $('#order_table').attr('data-upload_invoice_url');

        url = url.replace('###', orderId);

        let files = [];
        $('.js-file-input').each(function(){
            let file = $(this).data('base64');
            files.push(file)
        })

        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                'file': files
            },
        }).done(function (result) {

            $("#payment_form_container").html(result.form);
            $("#payment_form").submit();

        }).fail(function (xhr, textStatus, errorThrown) {

        });
    }
}
