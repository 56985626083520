/**
 *
 */
export default class New {

    constructor(obj) {
        this.dataObj = obj;
        this.events();
    }

    events() {
        let self = this;
    }

    getContent(order) {
        return `<button class="btn btn-sm btn-primary p-0 pl-1 pr-1" data-selected_order_id="` + order.id + `">Seçildi</button>`;
    }

}
