import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    /**
     *
     * @param item
     * @param data
     * @returns {(*|string)[]}
     */
    constructor(item, data) {

        super();

        let self = this;

        return [
            item.id,
            item.tracking_number,
            self.shippingLogo(item),
            `<div class="open-modal" data-url="` + data.update_url.replace('###', item.order_id) + `" data-onload="vendorOrderModal" role="button">
                <i class="fas fa-external-link-alt"></i> ` + item.order_id + `
            </div>`,
            `<span class="order_satus" style="background:#` + item.orderStatus.color + `;">` + item.orderStatus.name + `</span>`,
            self.priceFormat(item.shipment_price, item.currency_name),
            self.priceFormat(item.shipment_actual_price, item.currency_name),
            self.yesNo(item.is_paid),
            this.timeAgo(item.created_at),
            item.invoice_no,
        ];
    }

    /**
     *
     * @param price
     * @param currency
     * @returns {string}
     */
    priceFormat(price, currency) {
        if (price == null || price == 0) {
            price = '-';
        } else {
            price = '<b>' + price + '</b> <small>' + currency + '</small>';
        }
        return `<div class="text-center">` + price + `</div>`;
    }

    /**
     *
     * @param item
     * @returns {string}
     */
    shippingLogo(item) {
        if (item.shipping_id != null) {
            return `
                <div class="border bg-white shipping-logo" data-toggle="tooltip"  title="` + item.shipping_variety_name + `">
                    <img src="/storage/img/shipping/` + item.shipping_id + `.webp" alt="">
                </div>
            `;

        }
        return `<div class="text-center"> - </div>`;
    }
}
