import {FormatDataTable} from '../formatDataTable';
import {TrackingLink} from "../../trackingLink";

/**
 *
 */
export class DataTableOnLoad extends FormatDataTable {

    /**
     *
     */
    constructor(item, data) {

        super()

        this.item = item;
        let self = this;

        let trackingLink = new TrackingLink(item);

        return [
            self.id(),
            item.which_platform_string,
            this.item.order_id,
            self.getOrderStatus(),
            self.integrationLogo(item),
            item.email,
            typeof item.address.full_name != 'undefined' ? '<b>' + item.address.full_name + '</b>' : '',
            trackingLink.show(),
            (item.is_express == 1 ? 'express' : 'economic'),
            item.country,
            item.packages.length,
            item.total,
            self.shipmentPrice(),
            self.getTime(),

            ($.inArray(item.real_status, [27]) === -1 ? self.updateButton(data, item.order.id, 'vendorOrderModal') : self.disabledButton()),
            self.deleteButton(item.order),
            self.lastButton(),
        ];
    }

    getOrderStatus() {
        let status = this.item.order.order_statuss;
        return `
            <span class="order_satus" style="background:#` + status.color + `;">
                ` + status.name + `
            </span>`;
    }

    getTime() {
        const orderDate = new Date(this.item.order.created_at);
        let options = [
            {day: 'numeric'},
            {month: 'short'},
            {year: 'numeric'}
        ];
        let joined = this.join(orderDate, options, '-');

        return '<div class="text-nowrap">' + joined + '</div>';
    }

    join(date, options, separator) {
        function format(option) {
            let formatter = new Intl.DateTimeFormat('en', option);
            return formatter.format(date);
        }

        return options.map(format).join(separator);
    }

    /**
     *
     */
    integrationLogo() {
        let order = this.item.order;
        let integration_data = order.integration_data;

        if (order.which_platform != 7) {
            return '';
        }

        if (typeof integration_data == 'undefined') {
            return '';
        }

        if (integration_data == null) {
            return '';
        }

        if (typeof integration_data != 'undefined') {
            return '<img src="/img/integrations/' + integration_data.entegration_id + '.png" class="vendor-img-sm" alt="integration">';
        }
    }

    /**
     *
     */
    id() {
        let order = this.item.order;
        // data-shipping_service_id="` + order.shipping_service_id + `"

        return `
            <div
                id="order_` + order.id + `"
                data-order="` + JSON.stringify(order).replaceAll("'", '\`').replaceAll('\"', "'") + `"
                data-description="` + (order.description ? order.description : order.defaultDescription) + `"
                data-is_labellable="` + order.is_labellable + `"
                data-is_labellable_reason="` + order.is_labellable_reason + `"
                data-country_id="` + order.receiver_address.country_id + `"
                data-order_id="` + order.id + `"
                data-is_return="` + (order.return_of_id == null ? 0 : 1) + `">
                ` + order.id + `
            </div>`
    }

    /**
     *
     */
    shipmentPrice() {
        let price = this.item.shipment_actual_price;
        if (price > 0) {
            return '<b>' + price + '</b><br><small style="color:#999">' + this.item.shipment_currency_text + '</small>'
        }

        return '-';
    }

    /**
     *
     */
    lastButton() {
        let button = '';

        let order = this.item.order;
        if ([26, 13].includes(order.real_status)) {
            button = `
                <span class="btn btn-circle btn-sm btn-warning js-open-label"  role="button">
                    <i class="fas fa-folder-plus"></i>
                </span> `;
        }

        if (order.real_status == 14) {
            button = `
                <span
                    class="btn btn-circle btn-sm btn-info js-print-single-zpl-label"
                    role="button"
                    data-id="` + order.id + `" >
                    <i class="fas fa-print"></i>
                </span>`;
        }

        if (button == '') {
            button = this.disabledButton('fa-folder-plus');
        }

        return ` <div class="text-center"> ` + button + ` </div>`;
    }
}
