import {FormatDataTable} from '../formatDataTable';

/**
 *
 * @param formatObj
 * @param item
 * @param data
 * @returns {[undefined,string,*,(string|*),(string|*)]}
 * @constructor
 */
export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super()

        let self = this;

        let priceUpdateUrl = data.update_shipping_price_url.replace('###', item.id);
        let costUrl = data.costs_url.replace('###', item.id);

        return [
            item.id,
            '<img src="' + item.img + '" class="img-fluid img-thumbnail">',
            item.account_number,
            '<b>' + item.name + '</b>',
            `<div class="text-center js-update-zones" title="" data-id="` + item.id + `"">
                <span href="#" class="btn btn-secondary btn-circle btn-sm">
                   <i class="fas fa-map-marked-alt"></i>
                </span>
            </div>`,
            `<div class="text-center open-modal" title="" data-url="` + priceUpdateUrl + `">
                <span href="#" class="btn btn-warning btn-circle btn-sm">
                   <i class="fas fa-redo-alt"></i>
                </span>
            </div>`,
            `<div class="text-center js-goto-price-url" title="" data-id="` + item.id + `">
                <span href="#" class="btn btn-info btn-circle btn-sm">
                   <i class="fas fa-coins"></i>
                </span>
            </div>`,
            self.isActive(item.is_test),
            self.isActive(item.is_active),
            self.updateButton(data, item.id, 'adminShippingModal'),
        ];
    }

}
