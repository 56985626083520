import $ from "jquery";

export class CalculateTotals {

    constructor() {
        this.events();
        this.calculate();
    }

    /**
     *
     */
    events() {

        let self = this;

        $(document).off('input', 'input[name=currency_value]').on('input', 'input[name=currency_value]', function () {
            self.calculate();
        });
        $(document).on('input', 'input[name^=unit_price]', function () {
            self.calculate();
        });
        $(document).off('input', 'input[name^=quantity]').on('input', 'input[name^=quantity]', function () {
            self.calculate();
        });
    }

    /**
     *
     */
    calculate() {

        $('.package').each(function () {

            let total = 0;
            $(this).find('tr').each(function () {

                let price = parseFloat($(this).find('input[name^=unit_price]').val());
                let quantity = parseFloat($(this).find('input[name^=quantity]').val());

                let t = (price * quantity);
                if (!isNaN(t)) {
                    total += t
                }
            });

            let currency = $('select[name=currency] option:selected').data('sign');
            let currencyValue = $('input[name=currency_value]').val();

            let tlValue = '';
            if (!isNaN(currencyValue)) {
                tlValue = '/ ' + (currencyValue * total).toFixed(2) + ' TL';
            }

            $(this).find('.js-package-total').html('<b>' + total.toFixed(2) + '</b> ' + currency + ' ' + tlValue);

        });

    }
}
