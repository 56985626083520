import $ from "jquery";
import {AdminContent} from './items/adminContent';
import {VendorContent} from './items/vendorContent';

/**
 *
 */
export class ChildRow {

    /**
     *
     * @param data
     */
    constructor(data) {
        this.dataObj = $(data[0]);
    }

    /**
     *
     */
    getRowContent() {
        if (this.dataObj.attr('data-is_admin') == 'true') {
             this.childRow = new AdminContent(this.dataObj);
        }else{
            this.childRow = new VendorContent(this.dataObj);
        }

        return this.childRow.getRowContent();
    }

    /**
     *
     */
    afterCreated(){
        this.childRow.afterCreated();
    }

}
